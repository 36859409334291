import * as glide from "@glideapps/tables";

const stripe = Stripe(
  "pk_live_51NbDOiBYRlazlaFtnVONKUha42zKZ8kzI2sJbAxQcC9L5yDn8GomUB2w4b0d5ft9BFxX66EDpTHL89VhAWpvdSG400ymrlNBcN"
);

const usersTable = glide.table({
  token: "9c2f2ee2-ee58-4e71-9544-e737d8b70007",
  app: "2XxKdguGPjpdOme2bJhI",
  table: "native-table-WTVXjaq5CRLxfJkG0LIg",
  columns: {
    email: { type: "email-address", name: "Email" },
    checkoutSessionComplete: { type: "boolean", name: "mtPFR" },
    checkoutSessionError: { type: "boolean", name: "ncTxC" },
  },
});

const containerHTMLElement = document.querySelector("#container");
const urlParams = new URLSearchParams(window.location.search);
const priceParam = urlParams.get("price");
const productNameParam = urlParams.get("productName");
const glideRowId = urlParams.get("glideRowId");
const checkoutSession = urlParams.get("session_id");
const accountId = urlParams.get("accountId");
const bookAccountGlideId = urlParams.get("bookAccountGlideId");
const paymentMethodGlideId = urlParams.get("paymentMethodGlideId");
const businessGlideId = urlParams.get("businessGlideId");
const fxToUsd = urlParams.get("fxToUsd");
const totalAmount = urlParams.get("totalAmount");
const userEmail = urlParams.get("userEmail");
const glideId = urlParams.get("glideId");

const completeCheckoutSession = async ({ checkoutSession }) => {
  const users = await usersTable.get();
  try {
    const response = await fetch(
      `https://x3t2-yltb-5hil.n7.xano.io/api:QzwMkQlw/stripe_process_payment_card`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          checkout_session_id: checkoutSession,
          fx_to_usd: +fxToUsd,
          book_account_id: bookAccountGlideId,
          business_glide_id: businessGlideId,
        }),
      }
    );

    const response2 = await fetch(
      `/get-payment-status/${checkoutSession}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        }
      }
    );

    if (response) {
      console.log("process payment endpoint response",  await response.json());
      const user = users.find((user) => user.email === userEmail);
      await usersTable.update(user["$rowID"], {
        mtPFR: true,
      });
    }
  } catch (e) {
    console.log(e)
    const user = users.find((user) => user.email === userEmail);
    await usersTable.update(user["$rowID"], {
      ncTxC: true,
    });
  }
};

const createCheckoutSession = async () => {
  const response = await fetch(
    "https://x3t2-yltb-5hil.n7.xano.io/api:QzwMkQlw/stripe_create_checkout_session",
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        currency: "usd",
        productName: productNameParam || "Pago",
        amount: priceParam || 1,
        quantity: 1,
        accountId: accountId || "",
        mode: "payment",
        applicationFeeAmount: 0,
        glideRowId,
        bookAccountGlideId,
        paymentMethodGlideId,
        businessGlideId,
        fxToUsd,
        totalAmount,
        userEmail,
        glideId,
      }),
    }
  );
  const { data } = await response.json();
  const { clientSecret } = await data;
  const checkout = await stripe.initEmbeddedCheckout({
    clientSecret,
  });

  containerHTMLElement.style.display = "none";
  checkout.mount("#checkout");
};

if (checkoutSession) {
  completeCheckoutSession({ checkoutSession });
} else {
  createCheckoutSession();
}
